<template>
    <content-view>
        <dashboard-view/>
    </content-view>
</template>

<script>
import { metaTitle } from '@/mixins/meta_title'

export default {
    name: 'Dashboard',
    mixins: [metaTitle],
    components: {
        DashboardView : () => import('@/components/dashboard/Dashboard'),
        ContentView : () => import('@/views/menu/ContentView')
    }
}
</script>

<style lang="scss" scoped>
.content-view {
    background-color: #f0f2f5;
}
</style>
